<template>
<AppLayout>
    <template v-slot:appContent>
        <section class="users-list-wrapper">
            <div class=" d-flex justify-content-between align-items-center pt-2 pt-md-2 pt-xl-0 ">
                <h4 class="">Payments/List</h4>
            </div>
            <div class="users-list-filter px-1 my-2 py-2 border rounded">
                <div>
                    <div class="row">
                        <div class="col-12 col-sm-6 col-md-3 col-lg-3">
                            <label>Show Per Page</label>
                            <VueMultiselect :allow-empty="false" v-model="selectedPagination" class="" :options="paginationOptions" :close-on-select="true" label="name" track-by="name" :show-labels="false" />
                        </div>
                        <div class="col-12 col-sm-6 col-md-3 col-lg-3">
                            <label>Reference</label>
                            <input v-model="getPaymentsParams.reference" type="text" class="form-control" placeholder="Reference">
                        </div>
                        <div class="col-12 col-sm-6 col-md-3 col-lg-3">
                            <label>Payment Type</label>
                            <VueMultiselect :allow-empty="false" v-model="selectedPaymentType" class="" :options="paymentTypeOptions" :close-on-select="true" label="name" track-by="name" :show-labels="false" />
                        </div>

                        <div class="col-12 col-sm-6 col-md-3 col-lg-3">
                            <div class="form-group">
                                <label>Date</label>
                                <div class="d-flex align-items-center date-picker-wrapper">
                                    <div class="d-flex align-items-center">
                                        <i class='bx bx-calendar'></i>
                                    </div>
                                    <div class="w-100 pl-1 ">
                                        <div class="w-100 ">
                                            <DatePicker v-model="getPaymentsParams.created_at" :update-on-input="true" :masks="{input: ['DD MMMM YYYY']}" :model-config="{ type: 'string', mask: 'YYYY-MM-DD'}">
                                                <template v-slot="{ inputValue, inputEvents }">
                                                    <input class="form-control" :value="inputValue" v-on="inputEvents" />
                                                </template>
                                            </DatePicker>
                                        </div>
                                    </div>
                                </div>
                                <div class="text-danger"></div>
                            </div>
                        </div>

                    </div>
                    <div class="row pt-2">
                        <div class="col-12 col-sm-12 col-md-9  col-lg-9">
                            <input v-model="getPaymentParams.where_has_user_search_query" class="form-control search-product-input-element" type="text" placeholder="">
                        </div>
                        <div class="col-12 col-sm-12 col-md-3 col-lg-3">
                            <button type="reset" class="btn btn-primary btn-block glow users-list-clear mb-0" @click="applyPaymentsFilter(null)">Filter
                            </button>
                        </div>
                    </div>
                </div>

            </div>
            <div class="users-list-table">
                <div class="card">
                    <div class="card-body">
                        <div class="table-responsive">
                            <table id="users-list-datatable" class="table">
                                <thead>
                                    <tr>
                                        <th class="position-relative" :style="{width:'15%'}">CUSTOMER</th>
                                        <th class="position-relative" :style="{width:'12%'}">REFERENCE</th>
                                        <th class="position-relative" :style="{width:'20%'}">PAY FOR</th>
                                        <th class="position-relative" :style="{width:'15%'}">DATE</th>
                                        <th class="position-relative" :style="{width:'15%'}">PANEL</th>
                                        <th class="position-relative" :style="{width:'5%'}">STATUS</th>
                                        <th class="position-relative" :style="{width:'13%'}">PRICE</th>
                                        <th class="position-relative" :style="{width:'5%'}">Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(payment) in payments" :key="payment.id">
                                        <td>
                                            <div>
                                                <p class="mb-0 text-capitalize"> {{ userFullName[payment.id] }}</p>
                                            </div>
                                            <div>
                                                <small>{{ payment.code }}</small>
                                            </div>
                                        </td>
                                        <td>
                                            <p class="mb-0">{{ payment.reference }}</p>
                                        </td>
                                        <td>
                                            <div>
                                                <p class="mb-0 text-capitalize">{{ paymentComeFrom[payment.id] }} </p>
                                            </div>
                                            <div>
                                                <small class="text-capitalize">paid by
                                                    <span class="badge badge-sm" :class="payment.type === 'Card'? 'badge-light-success':(payment.type==='Cash'?'badge-light-warning':'badge-light-primary')">
                                                        {{ payment.type }}
                                                    </span>
                                                </small>
                                            </div>
                                            <div>
                                                <span v-if="payment.type === 'Card'">
                                                    {{ payment?.paymentDetails?.payment_gateway_id }}</span>
                                                <span v-if="payment.type === 'Cash'">Paid</span>
                                                <span v-if="payment.type === 'EFTPOS'">
                                                    {{ payment?.paymentDetails?.reference }}</span>
                                                <span v-if="payment.type === 'Afterpay'">
                                                    {{ payment?.paymentDetails?.payment_gateway_id }}</span>
                                            </div>
                                        </td>
                                        <td>{{ getDisplayDate(payment.created_at) }}</td>
                                        <td>
                                            <p class="mb-0">{{ payment.panel }}</p>
                                        </td>
                                        <td>
                                            <span v-if="payment.type === 'Card'" class="badge badge-sm badge-light-success">
                                                {{ payment?.paymentDetails?.status }}</span>
                                            <span v-if="payment.type === 'Cash'">Paid, Handover Status:</span>
                                            <span v-if="payment.type === 'Cash'" class="badge badge-sm badge-light-warning">
                                                {{ payment?.paymentDetails?.status }}</span>
                                            <span v-if="payment.type === 'EFTPOS'" class="badge badge-sm badge-light-primary">
                                                {{ payment?.paymentDetails?.reference }}</span>
                                            <span v-if="payment.type === 'Afterpay'" class="badge badge-sm badge-light-primary">
                                                {{ payment?.paymentDetails?.status }}</span>
                                        </td>

                                        <td>
                                            <p class="mb-0 "> $ {{ parseFloat(payment.total_transaction / 100).toFixed(2) }}</p>
                                        </td>

                                        <td>
                                            <div class="d-flex align-items-top justify-content-between">
                                                <div class="cursor-pointer view-edit-delete-icon">
                                                    <button @click="getSinglePaymentFromPayments(payment.id)" class="m-0 p-0 bg-transparent border-0" data-toggle="modal" data-target="#paymentQuickViewModal" :style="{height:'18px'}">
                                                        <i class='bx bx-show'></i>
                                                    </button>
                                                </div>

                                                <!--                        <div class="text-decoration-none cursor-pointer view-edit-delte-icon">
                                                  <div class=" " data-toggle="modal" data-target="#deleteModal" :style="{height:'18px'}">
                                                    <i class='bx bx-x'></i>
                                                  </div>
                                                </div>-->

                                                <!--                        <div class="dropdown py-0 px-0 view-edit-delte-icon" :style="{height:'18px'}">
                                                                                                <span type="button" class="px-0 py-0"
                                                                                                      data-toggle="dropdown"
                                                                                                      aria-haspopup="true"
                                                                                                      aria-expanded="false">
                                                                                                    <i class='bx bx-dots-vertical-rounded h-100'></i>
                                                                                                </span>
                                                  <div class="dropdown-menu">

                                                    <button class="dropdown-item" data-toggle="modal" data-target="#paymentDetailsModal">View
                                                      Payment Details
                                                    </button>
                                                  </div>
                                                </div>-->
                                            </div>
                                        </td>

                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <ListPagination position="left" :pagination-data="paginateLinks" @getClickedPage="applyPaymentsFilter" />
                    </div>
                </div>
            </div>
            <!--        <DeleteAlertModal/>-->
            <PaymentQuickViewModal :current-payment="singlePaymentObj" :payment-type="singlePaymentType" />
            <PaymentDetailsModal />
        </section>
    </template>
</AppLayout>
</template>

<script>
import AppLayout from '@/layouts/backEnd/AppLayout.vue'
import ListPagination from "@/components/backEnd/pagination/ListPagination";
import PaymentQuickViewModal
from "@/views/backEnd/AccountAndFinance/Finance/AllPayments/includes/PaymentQuickViewModal";
import PaymentDetailsModal from "@/views/backEnd/AccountAndFinance/Finance/AllPayments/includes/PaymentDetailsModal";

import ShowToastMessage from "@/components/backEnd/mixins/ShowToastMessage";
import Loader from "@/components/backEnd/mixins/Loader";
import VueMultiselect from "vue-multiselect";
import {
    mapActions,
    mapGetters
} from "vuex";
import {
    DatePicker
} from "v-calendar";

export default {
    name: "AllPayments",
    mixins: [ShowToastMessage, Loader],
    components: {
        PaymentDetailsModal,
        PaymentQuickViewModal,
        DatePicker,
        AppLayout,
        // DeleteAlertModal,
        VueMultiselect,
        ListPagination,

    },
    data() {
        return {
            selectedPaymentType: {
                value: '',
                name: 'None'
            },
            selectedPagination: {
                value: '',
                name: 'Default'
            },

            technicalValue: {
                name: 'Any ',
                language: 'Any '
            },
            paymentValue: {
                name: 'Any ',
                language: 'Any '
            },

            customerTypeSelectedValue: '',
            statusSelectedValue: '',

            currentGeekDetails: {
                id: "",
                name: "",
                address: "",
                type: "",
                phone: "",
                email: "",
                status: "",

            },
            getPaymentsParams: {
                reference: '',
                type: '',
                panel: '',
                order_by_id: 'DESC',
                where_has_user_search_query: '',
                created_at: '',
                with_relation: [
                    'cardPayment',
                    'cardPayment.paidBy.customer.address.business',
                    'cashPayment',
                    'cashPayment.paidBy.customer.address.business',
                    'eftPayment',
                    'eftPayment.paidBy.customer.address.business',
                    'afterpayPayment',
                    'afterpayPayment.paidBy.customer.address.business',
                    'appointmentPayment',
                    'orderPaymentsByReference'
                ],
                paginate: 1,
                pagination: '',
                page: ''
            },
            getPaymentParams: {},
            getSettingsParams: {
                type: ['default'],
                key: ['default_pagination', 'default_state', 'default_customer_type'],
            },
            singlePaymentObj: {},
            singlePaymentType: ''

        }
    },
    computed: {
        ...mapGetters({
            payments: 'appPayments/payments',
            paginateLinks: 'appPayments/paginateLinks',
        }),

        paymentTypeOptions() {
            return [{
                    value: 0,
                    name: 'Cash'
                },
                {
                    value: 1,
                    name: 'Card'
                },
                {
                    value: 2,
                    name: 'EFTPOS'
                },
                {
                    value: 3,
                    name: 'Afterpay'
                },
            ]
        },

        userFullName() {
            let dataArray = {};
            this.payments.map((payment) => {
                if (payment.type === 'Card' && payment.cardPayment !== null) {
                    dataArray[payment.id] = `${payment?.cardPayment?.paidBy?.first_name} ${payment?.cardPayment?.paidBy?.last_name}`
                }
                if (payment.type === 'Cash' && payment.cashPayment !== null) {
                    dataArray[payment.id] = `${payment?.cashPayment?.paidBy?.first_name} ${payment?.cashPayment?.paidBy?.last_name}`
                }
                if (payment.type === 'EFTPOS' && payment.eftPayment !== null) {
                    dataArray[payment.id] = `${payment?.eftPayment?.paidBy?.first_name} ${payment?.eftPayment?.paidBy?.last_name}`
                }
                if (payment.type === 'Afterpay' && payment.afterpayPayment !== null) {
                    dataArray[payment.id] = `${payment?.afterpayPayment?.paidBy?.first_name} ${payment?.afterpayPayment?.paidBy?.last_name}`
                }
            });
            return dataArray;
        },

        paymentComeFrom() {
            let dataArray = {};
            this.payments.map((payment) => {
                if (payment.orderPaymentsByReference !== null && payment.appointmentPayment === null) {
                    dataArray[payment.id] = 'order';
                }
                if (payment.orderPaymentsByReference === null && payment.appointmentPayment !== null) {
                    dataArray[payment.id] = 'appointment';
                }
            });
            return dataArray;
        },

        paginationOptions() {
            return [{
                value: this.$store.getters['appSettings/settingDefaultPagination'].value,
                name: 'Default'
            }, {
                value: 25,
                name: '25 Entries'
            }, {
                value: 50,
                name: '50 Entries'
            }, {
                value: 100,
                name: '100 Entries'
            }];
        },
    },
    watch: {
        selectedPaymentType() {
            this.getPaymentsParams.type = this.selectedPaymentType.value;
        }
    },
    methods: {
        ...mapActions({
            getPayments: 'appPayments/getPayments',
            getPayment: 'appPayments/getPayment',
            getSettings: 'appSettings/getSettings',
        }),

        async generatePaymentTypeStatus() {
            await this.payments.forEach((payment) => {
                if (payment.type === 'Card' && payment.cardPayment !== null) {
                    payment['paymentDetails'] = {
                        card_type: payment.cardPayment.card_type,
                        payment_gateway: payment.cardPayment.payment_gateway,
                        payment_gateway_id: payment.cardPayment.payment_gateway_id,
                        status: payment.cardPayment.status
                    }
                }
                if (payment.type === 'Cash' && payment.cashPayment !== null) {
                    payment['paymentDetails'] = {
                        status: payment.cashPayment.handover_status
                    }
                }
                if (payment.type === 'EFTPOS' && payment.eftPayment !== null) {
                    payment['paymentDetails'] = {
                        reference: payment.eftPayment.payment_reference
                    }
                }
                if (payment.type === 'Afterpay' && payment.afterpayPayment !== null) {
                    payment['paymentDetails'] = {
                        token: payment.afterpayPayment.token,
                        payment_gateway_id: payment.afterpayPayment.payment_gateway_id,
                        status: payment.afterpayPayment.status
                    }
                }
            });
        },

        async getSinglePaymentFromPayments(paymentId) {
            this.singlePaymentObj = await this.payments.find(({
                id
            }) => id === paymentId);
            this.singlePaymentType = this.paymentComeFrom[paymentId];
        },
        async getPaymentList(paymentsParams) {
            this.loader(true);
            await this.getPayments(paymentsParams)
                .then((response) => {
                    this.generatePaymentTypeStatus();
                    this.loader(false);
                    if (response && response.message && response.status !== 200) {
                        this.showToastMessage(response);
                    }
                });
        },

        async getSinglePayment(id) {
            const paramObj = {
                id: id,
                params: this.getPaymentParams,
            };
            await this.getPayment(paramObj)
                .then((response) => {
                    if (response && response.status && response.status === 200) {
                        this.payment = this.$store.getters['appPayments/payment'];
                    }

                    if (response && response.message && response.status !== 200) {
                        this.showToastMessage(response);
                    }

                });
        },

        async getSettingList(settingParams) {
            await this.getSettings(settingParams);
        },

        async applyPaymentsFilter(pageNumber) {
            await this.loader(true);
            this.getPaymentsParams.page = pageNumber;
            await this.getPaymentList(this.getPaymentsParams);
            await this.loader(false);
        },

        getDisplayDate(date) {
            let newDate = date ? new Date(date) : new Date;
            return new Date(newDate).toLocaleDateString("en-AU", {
                day: "numeric",
                month: "short",
                year: "numeric",
            })
        },
    },
    async mounted() {
        await this.loader(true);
        let reference = this.$route.params.reference;
        if (reference !== undefined) {
            this.getPaymentsParams.reference = reference;
        }
        await this.getSettingList(this.getSettingsParams);
        await this.getPaymentList(this.getPaymentsParams);
        await this.loader(false);
    }

}
</script>

<style scoped>
.badge {
    display: inline-block;
    padding: 4px 8px !important;
    font-size: 10px !important;
    font-weight: 400;
    line-height: 1 !important;
    text-align: center !important;
    white-space: nowrap !important;
    vertical-align: baseline !important;
    border-radius: 0.375rem !important;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

@media only screen and (max-width: 1337px) and (min-width: 320px) {
    .table {
        white-space: nowrap !important;
    }

}
</style>
