<template>
  <div class="modal fade " id="paymentQuickViewModal" tabindex="-1" role="dialog"
       aria-labelledby="paymentQuickViewModal" aria-hidden="true" data-keyboard="false" data-backdrop="static">
    <div class="modal-dialog modal-dialog-scrollable modal-xl">
      <div class="modal-content">
        <div class="modal-header bg-primary">
          <h5 class="modal-title white" id="">Quick view</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="resetData">
            <i class="bx bx-x"></i>
          </button>
        </div>
        <div class="modal-body">
          <table class="table table-striped">
            <tbody>
            <tr>
              <th>Payment ID</th>
              <td id="paymentViewId">{{ currentPayment.code }}</td>
            </tr>
            <tr>
              <th>Customer</th>
              <td>{{ userDetails.first_name }} {{ userDetails.last_name }}</td>
            </tr>
            <tr>
              <th>Email</th>
              <td>{{ userDetails.email }}</td>
            </tr>
            <tr>
              <th>Phone</th>
              <td>{{ userDetails.phone_number }}</td>
            </tr>
            <tr>
              <th>Address</th>
              <td>{{ customerCurrentAddressReadableFormat }}</td>
            </tr>
            <tr>
              <th>Pay Type</th>
              <td>{{ currentPayment.panel }}</td>
            </tr>
            <tr>
              <th>Pay Section</th>
              <td>{{ paymentType }}</td>
            </tr>
            <tr>
              <th>Amount</th>
              <td>{{ parseFloat(currentPayment.total_transaction / 100).toFixed(2) }}</td>
            </tr>
            <tr>
              <th>Date</th>
              <td>{{ currentPayment.created_at }}</td>
            </tr>
            <tr>
              <th>Status</th>
              <td>
                <span v-if="currentPayment.type === 'Card'" class="badge badge-sm badge-light-success">
                        {{ currentPayment?.paymentDetails?.status }}</span>
                <span v-if="currentPayment.type === 'Cash'">Paid, Handover Status:</span>
                <span v-if="currentPayment.type === 'Cash'" class="badge badge-sm badge-light-warning">
                        {{ currentPayment?.paymentDetails?.status }}</span>
                <span v-if="currentPayment.type === 'EFTPOS'" class="badge badge-sm badge-light-primary">
                        {{ currentPayment?.paymentDetails?.reference }}</span>
                <span v-if="currentPayment.type === 'Afterpay'" class="badge badge-sm badge-light-primary">
                        {{ currentPayment?.paymentDetails?.status }}</span>
              </td>
            </tr>
            </tbody>
          </table>

          <!-- payent gateway details  -->
          <div>
            <button v-if="this.currentPayment.type === 'Card' || this.currentPayment.type === 'Afterpay'" class="btn btn-primary d-flex align-items-center" @click="viewPaymentsDetails()">View payment details <i class="bx bx-show text-white" ></i> </button>
            <div v-if="isOpen">
              <div class="card-body px-0" >
                  <ul>
                    <li v-for="(value , key, index) in gatewayResponses" :key="index">
                      {{ snakeToTitle(key) }} : {{ value }}
                    </li>
                  </ul>
                  <div v-if="events.length > 0">
                    <table>
                      <thead>
                      <tr>
                        <th class="position-relative" :style="{width:'20%'}">ID</th>
                        <th class="position-relative" :style="{width:'30%'}">CREATED</th>
                        <th class="position-relative" :style="{width:'30%'}">EXPIRE</th>
                        <th class="position-relative" :style="{width:'10%'}">TYPE</th>
                        <th class="position-relative" :style="{width:'10%'}">AMOUNT</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr v-for="event in events" :key="event.id">
                        <td>{{ event.id }}</td>
                        <td>{{ event.created }}</td>
                        <td>{{ event.expires }}</td>
                        <td>{{ event.type }}</td>
                        <td>{{ event.amount.amount }} {{ event.amount.currency }}</td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
            </div>
          </div>
          <!-- <div class="collapsible collapse-icon accordion-icon-rotate">
            <div class="card collapse-header" @click="getPaymentGatewayInformation">
              <div id="headingCollapse1" class="card-header" data-toggle="collapse" role="button"
                   data-target="#collapse1" aria-expanded="false" aria-controls="collapse1">
                <span class="collapse-title">Payment Gateway Information</span>
              </div>
              <div id="collapse1" role="tabpanel" aria-labelledby="headingCollapse1" class="collapse">
                <div class="card-body px-0" ref="gatewayContainer">
                  <ul>
                    <li v-for="(value , key, index) in gatewayResponses" :key="index">
                      {{ snakeToTitle(key) }} : {{ value }}
                    </li>
                  </ul>
                  <div v-if="events.length > 0">
                    <table>
                      <thead>
                      <tr>
                        <th class="position-relative" :style="{width:'20%'}">ID</th>
                        <th class="position-relative" :style="{width:'30%'}">CREATED</th>
                        <th class="position-relative" :style="{width:'30%'}">EXPIRE</th>
                        <th class="position-relative" :style="{width:'10%'}">TYPE</th>
                        <th class="position-relative" :style="{width:'10%'}">AMOUNT</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr v-for="event in events" :key="event.id">
                        <td>{{ event.id }}</td>
                        <td>{{ event.created }}</td>
                        <td>{{ event.expires }}</td>
                        <td>{{ event.type }}</td>
                        <td>{{ event.amount.amount }} {{ event.amount.currency }}</td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div> -->
        </div>
        <div class="modal-footer border-0 py-1">
          <button type="button" class="btn btn-primary" data-dismiss="modal" @click="resetData">
            <i class="bx bx-x d-block d-sm-none"></i>
            <span class="d-none d-sm-block">Close</span>
          </button>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions} from "vuex";
import ShowToastMessage from "@/components/backEnd/mixins/ShowToastMessage";
import Loader from "@/components/backEnd/mixins/Loader";


export default {
  name: "PaymentQuickViewModal",

  mixins: [ShowToastMessage,Loader],

  props: {
    currentPayment: {type: Object, required: true},
    paymentType: {type: String, required: true}
  },
  data() {
    return {
      gatewayResponses: {},
      events: [],
      isOpen: false
    }
  },
 watch:{
  currentPayment(){
    this.gatewayResponses = {};
    this.events = [];
  },
  paymentType(){
    this.gatewayResponses = {};
    this.events = [];
  }

  
 },
  computed: {
    userDetails() {
      let dataArray = {};
      if (this.currentPayment.type === 'Card' && this.currentPayment.cardPayment !== null) {
        dataArray = this.currentPayment.cardPayment.paidBy;
      }
      if (this.currentPayment.type === 'Cash' && this.currentPayment.cashPayment !== null) {
        dataArray = this.currentPayment.cashPayment.paidBy;
      }
      if (this.currentPayment.type === 'EFTPOS' && this.currentPayment.eftPayment !== null) {
        dataArray = this.currentPayment.eftPayment.paidBy
      }
      if (this.currentPayment.type === 'Afterpay' && this.currentPayment.afterpayPayment !== null) {
        dataArray = this.currentPayment.afterpayPayment.paidBy
      }
      return dataArray
    },
    customerCurrentAddressReadableFormat() {
            if (this.userDetails?.customer?.address ?.business ?.name) {
                return `${this.userDetails?.customer?.address?.business?.name ?? ''} (${this.userDetails?.customer?.address?.street ?? ''}, ${ this.userDetails?.customer?.address?.suburb ?? ''} ${this.userDetails?.customer?.address?.state ?? ''} ${this.userDetails?.customer?.address?.post_code ?? ''}, ${ this.userDetails?.customer?.address?.country ?? ''})`;

            } else {
                return `${this.userDetails?.customer?.address?.street ?? ''}, ${ this.userDetails?.customer?.address?.suburb ?? ''} ${this.userDetails?.customer?.address?.state ?? ''} ${this.userDetails?.customer?.address?.post_code ?? ''}, ${ this.userDetails?.customer?.address?.country ?? ''}`;

            }
        }
  },
  methods: {
    ...mapActions({
      authorizationFind: 'appPaymentGateways/authorizationFind',
      paymentFind: 'appPaymentGateways/paymentFind',
      afterpayGetPaymentByOrderId: 'appAfterpayPaymentGateways/getPaymentByOrderId'
    }),

    async viewPaymentsDetails(){
      
      if ( this.currentPayment.type === 'Card' && this.paymentType === 'appointment') { 
       await this.paymentFindHandler(this.currentPayment.paymentDetails.payment_gateway_id);
       return;
      }
      if ( this.currentPayment.type === 'Card' && this.paymentType === 'order') { 
       await this.authorizationFindHandler(this.currentPayment.paymentDetails.payment_gateway_id);
       return;
      }
      if(this.currentPayment.type === 'Afterpay'){
        await this.afterpayGetPaymentByOrderIdHandler(this.currentPayment.paymentDetails.payment_gateway_id);
        return;
      }

      
      

       
    },

    async getPaymentGatewayInformation() {


      // card payment & authorize| paid status for Order
      if (this.isOpen === false && this.currentPayment.type === 'Card' && (this.currentPayment.paymentDetails.status === 'Authorized' || this.currentPayment.paymentDetails.status === 'Paid')) {
        let gateway_id = this.currentPayment.paymentDetails.payment_gateway_id;
        let loader = this.$loading.show({
          // Optional parameters
          container: this.$refs.gatewayContainer,
          canCancel: false,
          loader: 'bars',
          height: 80,
          width: 80,
          opacity: 1,
          color: '#5a8dee',
          onCancel: this.onCancel,
        });
        await this.createAuthorizationFind(gateway_id).then((response) => {
          if (response.status === 200) {
            loader.hide()
            this.gatewayResponses = response.data;

            this.isOpen = true;
          }
        });
      }

      // card payment & paid status for appointment
      if (this.paymentType === 'appointment' && this.isOpen === false && this.currentPayment.type === 'Card' && this.currentPayment.paymentDetails.status === 'Paid') {
        let gateway_id = this.currentPayment.paymentDetails.payment_gateway_id;
        let loader = this.$loading.show({
          // Optional parameters
          container: this.$refs.gatewayContainer,
          canCancel: false,
          loader: 'bars',
          height: 80,
          width: 80,
          opacity: 1,
          color: '#5a8dee',
          onCancel: this.onCancel,
        });
        await this.createPaymentFind(gateway_id).then((response) => {
          loader.hide()
          if (response.status === 200) {
            this.gatewayResponses = response.data
            this.isOpen = true;
          }
        });
      }

      // afterpay payment & paid| authorized status
      if (this.isOpen === false && this.currentPayment.type === 'Afterpay' && (this.currentPayment.paymentDetails.status === 'Paid' || this.currentPayment.paymentDetails.status === 'Authorized')) {
        let gateway_id = this.currentPayment.paymentDetails.payment_gateway_id;
        let loader = this.$loading.show({
          // Optional parameters
          container: this.$refs.gatewayContainer,
          canCancel: false,
          loader: 'bars',
          height: 80,
          width: 80,
          opacity: 1,
          color: '#5a8dee',
          onCancel: this.onCancel,
        });
        await this.createAfterpayGetPaymentByOrderId(gateway_id).then((response) => {
          loader.hide()
          if (response.status === 200) {
            // eslint-disable-next-line no-unused-vars
            const {refunds, orderDetails, events, ...restData} = response.data
            this.gatewayResponses = Object.fromEntries(Object.entries(restData).map(([key, value]) => {
              if (key === 'originalAmount' || key === 'openToCaptureAmount') {
                return [`${key}`, value.amount + ' ' + value.currency]
              }
              return [key, value]
            }));
            this.events = events;
            this.isOpen = true;
          }
        });
      }
    },

    async createAfterpayGetPaymentByOrderId(id) {
      return await this.afterpayGetPaymentByOrderId(id).then((response) => {
        if (response.message) {
          this.showToastMessage(response);
        }
        return response;
      });
    },
    async afterpayGetPaymentByOrderIdHandler(id) {
      this.loader(true);
      const response = await this.afterpayGetPaymentByOrderId(id);
      this.loader(false);

        if (response?.message) {
          this.showToastMessage(response);
        }
        if (response?.status === 200) {
          // eslint-disable-next-line no-unused-vars
          const {refunds, orderDetails, events, ...restData} = {...response.data};
            this.gatewayResponses = Object.fromEntries(Object.entries(restData).map(([key, value]) => {
              if (key === 'originalAmount' || key === 'openToCaptureAmount') {
                return [`${key}`, value.amount + ' ' + value.currency]
              }
              return [key, value]
            }));
            this.events = events;
            this.isOpen = true;

        }

        
     
    },

    async createAuthorizationFind(id) {
      let data = {
        authorization_id: id,
      }

      return await this.authorizationFind(data).then((response) => {
        if (response.message) {
          this.showToastMessage(response);
        }
        return response;
      });

    },
    async authorizationFindHandler(id) {
      let data = {
        authorization_id: id,
      }
      this.loader(true);
      const response = await this.authorizationFind(data);
      this.loader(false);
        if (response?.message) {
          this.showToastMessage(response);
        }
        if (response?.status === 200) {
         
            this.gatewayResponses = response.data;
            this.isOpen = true;

        }
        else {
          await this.paymentFindHandler(id); 

        }
       
     

    },

    async createPaymentFind(id) {
      let data = {
        payment_id: id,
      }

      return await this.paymentFind(data).then((response) => {
        if (response.message) {
          this.showToastMessage(response);
        }
        return response
      });

    },
    async paymentFindHandler(id) {
      let data = {
        payment_id: id,
      }
      this.loader(true);
      const response = await this.paymentFind(data);
      this.loader(false);

        if (response?.message) {
          this.showToastMessage(response);
        }
        if (response?.status === 200) {
          const gatewayResponse = {...response.data};
            if(gatewayResponse?.card){
              delete gatewayResponse.card;
            }
            this.gatewayResponses = gatewayResponse;
            this.isOpen = true;
        }
       
     
    },

    snakeToTitle(str) {
      return str.split('_').map(function (item) {
        return item.charAt(0).toUpperCase() + item.substring(1);
      }).join(' ');
    },

    resetData() {
      this.isOpen = false;
      this.gatewayResponses = {}
      this.events = []
    }

  },


}

</script>

<style>

</style>
